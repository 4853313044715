/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Bars3Icon, XMarkIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import ThemeToggler from '../Base/Layout/ThemeToggler';
import logo from '../../../images/retrohook.png';
import config from '../../../config';
import '../../css/Product.css';

// Type definitions
interface NavItem {
  name: string;
  href: string;
}

const EXTERNAL_URLS = {
  DOCS: 'https://docs.retrohook.com/docs/intro',
  DISCORD: 'https://discord.com/channels/947629811646480414/1112590923893833780',
  WORKSPACE: '/workspace',
};

interface ProductNavProps {
  currentSection: string;
  handleNavClick: (e: React.MouseEvent, href: string, name: string) => void;
  matrix: boolean;
  login: () => void;
  setMatrix: (value: boolean) => void;
}

// Extract navigation logic into separate component
const NavItems: React.FC<{
  items: NavItem[];
  currentSection: string;
  handleNavClick: (e: React.MouseEvent, href: string, name: string) => void;
  mobile?: boolean;
}> = function ({ items, currentSection, handleNavClick, mobile = false }) {
  const getNavItemClass = (name: string) => {
    const baseClass = 'nav-item-base';
    return name === currentSection ? `${baseClass} nav-item-active` : `${baseClass} nav-item-inactive`;
  };

  const navItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (index: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: index * 0.1,
        duration: 0.3,
      },
    }),
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
  };

  return (
    <>
      {items.map((item, index) => (
        <motion.a
          key={item.name}
          custom={index}
          variants={navItemVariants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          href={item.href}
          className={getNavItemClass(item.name)}
          onClick={(e) => handleNavClick(e, item.href, item.name)}
        >
          {item.name}
        </motion.a>
      ))}
    </>
  );
};

export default function ProductNav({ currentSection, handleNavClick, matrix, login, setMatrix }: ProductNavProps) {
  const { productNav: nav } = config;

  const renderLogo = (clickHandler: (e: React.MouseEvent) => void, size = 'text-3xl') => (
    <motion.button type="button" onClick={clickHandler} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <motion.img
        className="nav-logo-image"
        src={logo}
        alt="Retrohook logo"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      />
      <motion.span
        style={{ fontFamily: '"Gugi", sans-serif' }}
        className={`nav-logo-text ${size}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.3 }}
      >
        Retrohook
      </motion.span>
    </motion.button>
  );

  const handleSignIn = () => {
    login();
    setMatrix(!matrix);
  };

  const signInVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.3 },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
  };

  return (
    <Popover as="header" className="nav-header">
      <div className="nav-wrapper">
        <nav className="nav-container" aria-label="Global">
          <div className="nav-logo-section">
            <div className="nav-logo-wrapper">
              {renderLogo((e) => handleNavClick(e, '#top', 'Home'))}
              <div className="nav-mobile-menu">
                <Popover.Button className="nav-mobile-button">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <motion.div className="nav-desktop-links" initial="hidden" animate="visible">
              <NavItems items={nav} currentSection={currentSection} handleNavClick={handleNavClick} />
            </motion.div>
          </div>
          <a href={EXTERNAL_URLS.DOCS} target="_blank" className="block rounded-md text-base font-medium text-white cursor-pointer" rel="noreferrer">
            <BookOpenIcon className="h-6 w-6 text-gray-500 hover:text-purple-500" />
          </a>
          <ThemeToggler />
          <motion.div className="nav-signin-wrapper" variants={signInVariants} initial="hidden" animate="visible" whileHover="hover">
            <motion.button type="button" onClick={handleSignIn} className="nav-signin-button">
              Sign In
            </motion.button>
          </motion.div>
        </nav>
      </div>
      <AnimatePresence>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <Popover.Panel focus className="nav-mobile-panel">
            <motion.div
              className="nav-mobile-content"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              <div className="nav-mobile-header">
                {renderLogo((e) => handleNavClick(e, '#top', 'Home'), 'text-2xl')}
                <Popover.Button className="nav-mobile-close">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div className="nav-mobile-menu-items">
                <div className="nav-mobile-links">
                  <NavItems items={nav} currentSection={currentSection} handleNavClick={handleNavClick} mobile />
                </div>
              </div>
            </motion.div>
          </Popover.Panel>
        </Transition>
      </AnimatePresence>
    </Popover>
  );
}
