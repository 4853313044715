import React from 'react';
import { motion } from 'framer-motion';
import cool from '../../../images/cool.png';
import config from '../../../config';
import '../../css/Product.css';

const { featuresGrid, mainFeatures } = config;

interface Feature {
  id: number;
  imageUrl: string;
  category: {
    name: string;
    href: string;
  };
  href: string;
  title: string;
  preview: string;
}

function QuoteMark({ rotate = false }: { rotate?: boolean }) {
  return (
    <svg className={`quote-mark ${rotate ? 'rotate' : ''}`} fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
    </svg>
  );
}

export default function FeaturesGrid() {
  // Variants for section animations
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
      },
    },
  };

  // Variants for feature card animations
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: (index: number) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: index * 0.2,
        duration: 0.5,
        ease: 'easeOut',
      },
    }),
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="features-container">
      <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className="features-section">
        <motion.div variants={sectionVariants} className="section-header">
          <motion.h2 className="section-title">{featuresGrid.title}</motion.h2>
          <motion.p className="section-subtitle">
            <span>{featuresGrid.subtitle}</span>
          </motion.p>
          <motion.p className="section-description">
            <span>{featuresGrid.description}</span>
          </motion.p>
        </motion.div>

        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} className="features-grid">
          {mainFeatures.map((post: Feature, index: number) => (
            <motion.div key={post.id} custom={index} variants={cardVariants} whileHover="hover" className="feature-card">
              <motion.div className="feature-image-container">
                <motion.img className="feature-image" src={post.imageUrl} alt={post.title} whileHover={{ scale: 1.05 }} />
              </motion.div>
              <div className="feature-content">
                <div className="feature-text">
                  <motion.p className="feature-category" whileHover={{ scale: 1.1 }}>
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </motion.p>
                  <a href={post.href} className="feature-link">
                    <motion.p className="feature-title" whileHover={{ scale: 1.05 }}>
                      {post.title}
                    </motion.p>
                    <motion.p className="feature-preview" whileHover={{ scale: 1.03 }}>
                      {post.preview}
                    </motion.p>
                  </a>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="quote-section"
      >
        <div className="quote-container">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="image-container"
          >
            <div className="image-wrapper">
              <div className="image-aspect-ratio">
                <motion.img className="cool-image" src={cool} alt="Retrohook doc" whileHover={{ scale: 1.05 }} />
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="quote-content"
          >
            <div className="quote-text-container">
              <blockquote>
                <div>
                  <motion.p
                    className="quote-text"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <QuoteMark />
                    {featuresGrid.quote1}
                    <br />
                    <br />
                    {featuresGrid.quote2}
                    <QuoteMark rotate />
                  </motion.p>
                </div>
              </blockquote>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
