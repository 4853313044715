/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../context/ThemeContext';
import ChatwootWidget from '../components/Base/Layout/ChatWootWidget';
import {
  AuthenticatedLoader,
  FeaturesGrid,
  MatrixOfHL7,
  TermsOfServiceModal,
  PricingBox,
  RetrohookOverview,
  ContactUs,
  RetrohookHome,
  ProductNav,
} from '../components/Product';
import '../css/Product.css';

interface ProductProps {
  notify: () => void;
  signUp: () => void;
  login: () => void;
}

export default function Product({ notify, signUp, login }: ProductProps) {
  const { effectiveTheme } = useTheme();
  const { isAuthenticated } = useAuth0();
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [matrix, setMatrix] = useState(false);
  const [currentSection, setCurrentSection] = useState('Home');

  const handleNavClick = (e: React.MouseEvent<HTMLElement>, href: string, sectionName: string) => {
    e.preventDefault();

    if (href.startsWith('http')) {
      window.location.href = href;
      return;
    }

    setCurrentSection(sectionName);

    // Special handling for top/home navigation
    if (href === '#top' || href === '#home') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    const targetSection = document.querySelector(href);
    if (targetSection) {
      // Get the navbar height to account for the fixed header
      const navbar = document.querySelector('.nav-header');
      const navbarHeight = navbar ? navbar.getBoundingClientRect().height : 0;

      // Calculate position with offset
      const targetPosition = targetSection.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const toggleMatrix = () => setMatrix(!matrix);
  const toggleTermsOfService = () => setShowTermsOfService(!showTermsOfService);

  const closeModal = () => {
    setShowTermsOfService(false);
    toggleMatrix();
  };

  if (isAuthenticated) {
    return <AuthenticatedLoader handleNavClick={handleNavClick} />;
  }

  return (
    <main id="top" className="product-main">
      {matrix ? (
        <MatrixOfHL7 />
      ) : (
        <ProductNav login={login} currentSection={currentSection} handleNavClick={handleNavClick} matrix={matrix} setMatrix={toggleMatrix} />
      )}

      <ChatwootWidget />

      {showTermsOfService && <TermsOfServiceModal open={showTermsOfService} notify={notify} closeModal={closeModal} />}

      <div id="home" className="home-section">
        {matrix && <MatrixOfHL7 />}
        <RetrohookHome matrix={matrix} signUp={signUp} setMatrix={toggleMatrix} setShowTermsOfService={toggleTermsOfService} />
      </div>

      <div id="product" className="overview-section">
        {matrix ? <MatrixOfHL7 /> : <RetrohookOverview effectiveTheme={effectiveTheme} matrix={matrix} />}
      </div>

      <div id="features" className="features-section">
        {matrix ? <MatrixOfHL7 /> : <FeaturesGrid />}
      </div>

      <div id="pricing">
        {matrix ? (
          <MatrixOfHL7 />
        ) : (
          <div className="pricing-section">
            <div className="pricing-container">
              <div className="pricing-content">
                <PricingBox setRegisterModal={signUp} setMatrix={toggleMatrix} />
              </div>
            </div>
          </div>
        )}
      </div>

      <div id="contact" className="contact-section">
        {matrix ? <MatrixOfHL7 /> : <ContactUs effectiveTheme={effectiveTheme} matrix={matrix} />}
      </div>
    </main>
  );
}
