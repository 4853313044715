import { ChartBarIcon, LockClosedIcon, CodeBracketSquareIcon } from '@heroicons/react/24/outline';

import hospital from './images/hospital.png';
import happy from './images/happy.png';
import teams from './images/teams.png';
import serverRoom from './images/secure-tunnel.png';

export default {
  url: process.env.API_URL,
  wssUrl: process.env.WSS_URL,
  client_url: process.env.CLIENT_URL,
  stripe_api_key: process.env.STRIPE_API_KEY,
  ENV: process.env.ENV ? process.env.ENV : 'development',
  domain: process.env.AUTH0_DOMAIN ? process.env.AUTH0_DOMAIN : 'retrohook.us.auth0.com',
  clientId: process.env.AUTH0_CLIENT_ID ? process.env.AUTH0_CLIENT_ID : 'DIpZEFp4TLTQjfxdCLHOgnZlstTKFogd',
  scope: process.env.AUTH0_SCOPE
    ? process.env.AUTH0_SCOPE
    : 'read:current_user update:current_user_metadata read:current_user_metadata openid profile email w_member_social read:user user:follow read:org',
  installationPrice: 2000,
  // recurringPrice: 10000,
  nav: [
    { name: 'Dashboard', href: '/', current: false, icon: ChartBarIcon },
    {
      name: 'Workflows',
      href: '/workflows',
      current: false,
      icon: CodeBracketSquareIcon,
    },
    { name: 'Tunnels', href: '/tunnels', current: false, icon: LockClosedIcon },
  ],
  productNav: [
    { name: 'Home', href: '#top', current: true },
    { name: 'Product', href: '#product' },
    { name: 'Features', href: '#features' },
    { name: 'Pricing', href: '#pricing' },
    { name: 'Contact', href: '#contact' },
  ],
  mainFeatures: [
    {
      id: 1,
      title: 'Predictable, Affordable Pricing',
      href: 'https://docs.retrohook.com/docs/workflows',
      category: {
        name: 'Cost-Effective',
        href: 'https://docs.retrohook.com/docs/workflows',
      },
      imageUrl: hospital,
      preview: 'No surprise fees. No per-connection charges. Retrohook’s transparent pricing ensures you scale affordably and avoid budget nightmares.',
    },
    {
      id: 2,
      title: 'Secure, Instant Connectivity',
      href: 'https://docs.retrohook.com/docs/tunnels',
      category: {
        name: 'Built for Your Security',
        href: 'https://docs.retrohook.com/docs/tunnels',
      },
      imageUrl: serverRoom,
      preview:
        'Take complete control of your data. Retrohook deploys in your environment—no third parties or hidden risks—so you can integrate instantly and confidently.',
    },
    {
      id: 3,
      title: 'Host in Your Cloud, Own Your Data',
      href: 'https://docs.retrohook.com/docs/workspaces',
      category: {
        name: 'You Control Your Data',
        href: 'https://docs.retrohook.com/docs/workspaces',
      },
      imageUrl: happy,
      preview: 'Keep total ownership of your data—no middlemen, no hidden compliance risks. Retrohook runs in your cloud, on your terms.',
    },
    {
      id: 4,
      title: 'Future-Proof Your Integrations',
      href: 'https://docs.retrohook.com/docs/intro#workspaces--environments',
      category: {
        name: 'Avoid Vendor Lock-In',
        href: 'https://docs.retrohook.com/docs/intro#workspaces--environments',
      },
      imageUrl: teams,
      preview: 'Stay in control, even if other providers shut down. With Retrohook’s self-hosted model, you can pivot and scale with no vendor lock-in.',
    },
  ],

  heroSection: {
    title: 'Frictionless Healthcare Integrations',
    subtitle: 'Fast. Secure. No-Code.',
    cta: 'Start Free',
    ctaSubtitle: 'Close more deals and hydrate your app with healthcare data—no expertise required.',
  },

  overviewSection: {
    title: '⚡ Healthcare Integrations Without the Headaches ⚡',
    subtitle: 'Deliver your products with seamless, secure healthcare automations.',
    description:
      'Skip the roadblocks.  Retrohook removes the friction from healthcare integrations, helping you close deals and onboard customers faster. Stop waiting on slow, complex implementations—Retrohook lets you move at the speed of business.',
  },

  featuresGrid: {
    title: '🚀 The Fastest Path to Customer Success 🚀',
    subtitle: 'Go to Market with Confidence.',
    description:
      "Retrohook's no-code solution automates healthcare workflows instantly, letting you transform data into actionable webhooks—without engineering bottlenecks.",

    quote1: 'Retrohook cut weeks off our integration timeline. No engineers, no headaches—just instant connectivity.',
    quote2: 'Onboarding healthcare systems is effortless now. We focus on scaling, not troubleshooting.',
  },

  pricingSection: {
    title: 'Choose the Plan for Your Business Needs',
  },

  contactSection: {
    title: 'Start Integrating Today',
    description:
      'Retrohook makes healthcare integration effortless. Explore our docs, deploy in minutes, or connect with our team to see how we can support your success.',
    chatButton: 'Chat with an Expert',
  },

  developer: [
    'No-code workflow builder',
    'Modern HL7 message processor',
    'Templates for reusability',
    'Native EHR Event Automation',
    'Shared environment access',
    'Team mode collaboration',
  ],

  core: [
    'Everything in Developer',
    'Pro features (S3, FHIR, SFTP, Tunnels)',
    'Deployed in your AWS environment',
    'Feature updates and improvements',
    'Maintenance and security patches',
    '48-hour response support',
  ],

  updatesFeatures: [
    'Everything in Production',
    'Fully built and managed integrations',
    'Custom connectors & configurations',
    'Dedicated implementation support',
    'Active monitoring and same-day support',
    'White-glove onboarding & training',
  ],
};
