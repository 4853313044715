import React from 'react';
import { motion } from 'framer-motion';
import { CheckIcon } from '@heroicons/react/24/solid';
import config from '../../../config';

const { pricingSection } = config;

// Type definitions
interface PricingCardProps {
  title: string;
  features: string[];
  actionType: 'button' | 'link';
  actionText: string;
  price?: number;
  priceLabel?: string;
  cardClass: string;
  headerClass: string;
  onAction?: () => void | string;
}

// Extract PricingCard to a separate component
const PricingCard: React.FC<PricingCardProps> = function ({ title, features, actionType, actionText, price, priceLabel, cardClass, headerClass, onAction }) {
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  const featureVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (index: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: index * 0.1,
        duration: 0.3,
      },
    }),
  };

  return (
    <motion.div variants={cardVariants} initial="hidden" animate="visible" whileHover="hover" className={`pricing-card ${cardClass}`}>
      <motion.div
        className={`pricing-card-header ${headerClass}`}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="pricing-card-header-title">{title}</h2>
      </motion.div>
      <div className="pricing-card-features-container">
        <motion.ul initial="hidden" animate="visible" className="pricing-card-features-list">
          {features.map((feature, index) => (
            <motion.li key={feature} custom={index} variants={featureVariants} className="pricing-card-feature-item">
              <CheckIcon className="pricing-card-feature-icon" aria-hidden="true" />
              <span className="pricing-card-feature-text">{feature}</span>
            </motion.li>
          ))}
        </motion.ul>
      </div>
      <div className="pricing-card-action-container">
        {price !== undefined && (
          <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3, duration: 0.5 }} className="pricing-card-price-container">
            <span className="pricing-card-price">${price}</span>
            <span className="pricing-card-price-label">{priceLabel}</span>
          </motion.p>
        )}

        {actionType === 'button' ? (
          <motion.p className="pricing-card-action-text-button" onClick={onAction as () => void} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <span className="pricing-card-action-text">{actionText}</span>
          </motion.p>
        ) : (
          <motion.a
            className="pricing-card-action-link"
            href={onAction as unknown as string}
            rel="noreferrer"
            target="_blank"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="pricing-card-action-link-text">{actionText}</span>
          </motion.a>
        )}
      </div>
    </motion.div>
  );
};

// Type definition for component props
interface PricingBoxProps {
  setRegisterModal: () => void;
  setMatrix: () => void;
}

export default function PricingBox(props: PricingBoxProps) {
  const { developer, core, updatesFeatures, installationPrice, recurringPrice } = config;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={containerVariants} className="pricing-container">
      <div className="pricing-wrapper">
        <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="pricing-title-section">
          <p className="pricing-title">{pricingSection.title}</p>
        </motion.div>

        <div className="pricing-grid">
          <PricingCard
            title="Developer"
            features={developer}
            actionType="button"
            actionText="Sign Up Free"
            cardClass="pricing-card-developer"
            headerClass="pricing-card-developer-header"
            onAction={() => {
              props.setRegisterModal(true);
              props.setMatrix(true);
            }}
          />

          <PricingCard
            title="Production"
            features={core}
            actionType="link"
            // actionText="Schedule Demo"
            price={installationPrice}
            priceLabel="/month"
            cardClass="pricing-card-production"
            headerClass="pricing-card-production-header"
            onAction="https://calendly.com/clint_johnson/retrohook?hide_gdpr_banner=1"
          />

          <PricingCard
            title="Managed"
            features={updatesFeatures}
            actionType="link"
            actionText="Schedule"
            price={recurringPrice}
            priceLabel="/month"
            cardClass="pricing-card-updates"
            headerClass="pricing-card-updates-header"
            onAction="https://calendly.com/clint_johnson/retrohook?hide_gdpr_banner=1"
            // onAction={() => {
            // eslint-disable-next-line no-undef
            // window.$chatwoot.toggle('open');
            // }}
          />
        </div>
      </div>
    </motion.div>
  );
}
