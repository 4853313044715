import React from 'react';

export default function InputBox(props: any) {
  const { name, onChange, defaultValue, placeholder } = props;
  return (
    <div className="relative w-full border-2 border-gray-500 rounded-md px-2 py-1 shadow-sm focus-within:ring-1 focus-within:ring-cyan-300 focus-within:border-cyan-300">
      <label
        htmlFor={name}
        className="z-10 absolute -top-4 left-0 -mt-px inline-block px-1 text-gray-600 bg-gray-100 border border-gray-300 dark:bg-gray-700 rounded-md text-xs font-medium dark:text-gray-100"
      >
        {name}
      </label>
      <input
        type="text"
        name={name}
        id={name}
        onChange={onChange}
        value={defaultValue}
        placeholder={placeholder}
        className="block bg-transparent w-full border-0 p-0 text-gray-600 dark:text-gray-100 placeholder-gray-700  dark:placeholder-gray-300 focus:ring-0 sm:text-sm"
      />
    </div>
  );
}
